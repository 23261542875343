import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import detailContext from "../context/detailContext";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const detail = useContext(detailContext);
  const mode = useSelector((state) => state.mode);
  const [offset, setOffset] = useState(0);

  function animateParallax() {
    let cupOuter = document.getElementById("cup-outer");
    let cupInner = document.getElementById("cup-inner");
    let splash = document.getElementById("splash");
    let coffeeBeans = document.getElementById("coffee-beans");
    let shadow = document.getElementById("shadow");
    if (cupOuter && cupInner && splash && coffeeBeans) {
      cupOuter.style.left = offset * 0.05 + "px";
      cupInner.style.left = offset * 0.05 + "px";
      splash.style.top = offset * -0.04 + "px";
      splash.style.left = offset * -0.04 + "px";
      coffeeBeans.style.top = offset * 0.15 + "px";
      coffeeBeans.style.left = offset * 0.15 + "px";
      shadow.style.top = offset * -0.05 + "px";
      shadow.style.left = offset * -0.03 + "px";
    }
  }

  const navigate = useNavigate();
  const navigateToJoin = () => {
    navigate("/join");
  };

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  animateParallax();
  return (
    <div>
      <div className="container hero mt-3">
        <div className="row">
          <div
            className="col-lg-6 col-md-12 mt-5 p-4 mb-5"
            style={{ zIndex: 6 }}
          >
            <h1 className="mb-4 mt-5" data-aos="zoom-in-up">
              {detail.userDetail.heroTitle}
            </h1>
            <p className="lead justify mb-4">
              {detail.userDetail.heroSubtitle}
            </p>
            <span>
              <a
                className={`anime-button anime-button-${mode} btn btn-rounded btn-sm btn-${mode}`}
                onClick={navigateToJoin}
              >
                Join Us
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </a>
            </span>
          </div>
          <div className={`col-lg-6 col-md-12 banner d-none d-md-block`}>
            <img
              src={require("../assets/images/banner/cup-outer.png")}
              id="cup-outer"
              alt="cup-outer"
            />
            <img
              src={require("../assets/images/banner/cup-inner.png")}
              id="cup-inner"
              alt="cup-inner"
            />
            <img
              src={require("../assets/images/banner/splash.png")}
              id="splash"
              alt="splash"
            />
            <img
              src={require(`../assets/images/banner/coffee-beans.png`)}
              id="coffee-beans"
              alt="coffee-beans"
            />
            <img
              src={require(`../assets/images/banner/shadow.png`)}
              id="shadow"
              alt="shadow"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
