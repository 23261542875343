import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import detailContext from "../context/detailContext";

const Footer = () => {
  const detail = useContext(detailContext);
  const mode = useSelector((state) => state.mode);

  useEffect(() => {}, [detail.footer]);

  return (
    <div className={`container-fluid footer footer-${mode}`}>
      <div className="row padding-top pb-4">
        <div className="col d-flex justify-content-center align-items-center text-white">
          <h1>Let's get in touch</h1>
        </div>
      </div>
      <div className="row padding-bottom pt-2">
        <div className="col d-flex justify-content-center  align-items-center">
          {detail.footer.map((obj, delay) => {
            delay = delay + 100;
            return (
              <a
                key={obj.id}
                className={`btn btn-outline-${mode} btn-md btn-rounded mx-3`}
                title={obj.platform}
                data-aos="flip-left"
                data-aos-delay={delay}
                href={obj.url}
                target="_blank"
                rel="noreferrer"
              >
                {obj.tag}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Footer;
