import DetailContext from "./detailContext";
import { FaLinkedin, FaPhoneAlt } from "react-icons/fa";
import { TbLetterB } from "react-icons/tb";
import { SiGmail } from "react-icons/si";
import { useState } from "react";

const DetailState = (props) => {
  // const fileStashUrl = "https://demo.filestash.app/s/ACdfCB6/";
  const fileStashUrl = "https://cafebizarre.com.np/";

  // Details
  const [userDetail, setUserDetail] = useState({
    fullName: "Bizarre Bros",
    email: "basnetm02@gmail.com",
    mobileNumber: "9843242332",
    status: "ACTIVE",
    heroTitle: "Café Bizarre",
    heroSubtitle: "Presented by Bizarre Bros. - Serving more than just Coffee.",
    about:
      "<p>Located in Naxal, Kathmandu, opposite to Lisara Reception, Café Bizarre is a unique café concept introduced by Bizarre Bros. (four friends known for their hilarious skits on social media). Combining their creative flair with a passion for coffee, they've created a cozy spot where you can enjoy artisanal brews and light bites in a vibrant, fun atmosphere. Whether you're here for a caffeine fix or just to hang out, Café Bizarre promises a one-of-a-kind experience.</p>",
    jumbotronTitle:
      "We Serve A Unique Coffee Experience, Which You Won't Find Anywhere Else.",
    jumbotronText: "Experience Matters - Instant Coffee Ta Ghar Mai Chha Ni!",
  });

  // For About links
  const [links, setLinks] = useState([
    {
      id: "337abf89-d792-499c-87c8-641d42078aa1",
      platform: "Facebook",
      url: "https://www.facebook.com/",
      status: "ACTIVE",
    },
    {
      id: "337abf89-d792-499c-87c8-641d42078aa2",
      platform: "Instagram",
      url: "https://www.instagram.com/bizarrebros_/",
      status: "ACTIVE",
    },
    {
      id: "337abf89-d792-499c-87c8-641d42078aa3",
      platform: "Youtube",
      url: "https://www.youtube.com/@bizarrebros.",
      status: "ACTIVE",
    },
  ]);

  const [customer, setCustomer] = useState({
    fullName: "Not Found",
    verified: false,
    league: "No",
    coins: 0,
  });

  //Change the last letter 'BB' from TbLetterB to your preference and don't forget to import it above as well
  const aboutIcon = (
    <>
      <TbLetterB />
      <TbLetterB />
    </>
  );

  // For footer section
  const [footer, setFooter] = useState([
    {
      id: "0",
      platform: "LinkedIn",
      tag: <FaLinkedin />,
      url: "https://www.linkedin.com/in/manojbasnet/",
    },
    {
      id: "1",
      platform: "Mail",
      tag: <SiGmail />,
      url: "mailto:admin@cafebizarre.com.np",
    },
    {
      id: "2",
      platform: "Call",
      tag: <FaPhoneAlt />,
      url: "tel:+9779813121465",
    },
  ]);

  const detail = {
    fileStashUrl,
    userDetail,
    aboutIcon,
    links,
    footer,
    customer,
    setCustomer,
  };

  return (
    <DetailContext.Provider value={detail}>
      {props.children}
    </DetailContext.Provider>
  );
};

export default DetailState;
