import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DetailState from "./context/DetailState";
import "bootstrap/dist/css/bootstrap.css";
import LoadingBar from "react-top-loading-bar";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Aos from "aos";
import "aos/dist/aos.css";
import Home from "./components/Home";
import RedeemPanel from "./components/RedeemPanel";
import ProfileForm from "./components/ProfileForm";
import { Route, Routes } from "react-router-dom";
import CustomerDetail from "./components/CustomerDetail";
import Alert from "./components/Alert";
import NotFound from "./components/NotFound";
import JoinForm from "./components/JoinForm";
import ItemDetail from "./components/ItemDetail";
import ScrollToTop from "./components/ScrollToTop";
import MenuList from "./components/MenuList";

const App = () => {
  let mode = useSelector((state) => state.mode);
  let progress = 0;
  const [offset, setOffset] = useState(0);
  const changeProgress = () => {
    let progressVal = (offset / document.body.scrollHeight) * 100;
    progress = progressVal;
  };
  useEffect(() => {
    Aos.init();
    const onScroll = () => setOffset(window.pageYOffset);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  changeProgress();
  return (
    <DetailState>
      {/* <Preloader /> */}
      <LoadingBar
        color={mode === "dark" ? "#00C9A9" : "#634d1a"}
        progress={progress}
        height="4px"
      />
      <Alert />
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="view-profile" element={<ProfileForm />} />
        <Route path="customer-detail" element={<CustomerDetail />} />
        <Route path="join" element={<JoinForm />} />
        <Route path="redeem/:code" element={<RedeemPanel />} />
        <Route path="item-detail/:id" element={<ItemDetail />} />
        <Route path="menu" element={<MenuList />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </DetailState>
  );
};

export default App;
