import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import SectionTitle from "./SectionTitle";
import detailContext from "../context/detailContext";
import ContactForm from "./ContactForm";

const Contact = () => {
  const mode = useSelector((state) => state.mode);
  const detail = useContext(detailContext);
  const [newDate, setNewDate] = useState();
  const [mail, setMail] = useState({
    name: "",
    email: "",
    subject: "",
    message: "I'd love to chat with you about...",
  });
  const handleClose = () => {};

  const onChange = (formMail) => {
    setMail(formMail);
  };
  const handleDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  };
  useEffect(() => {
    setNewDate(handleDate());
  }, []);

  return (
    <div
      className={`container-fluid contact contact-${mode} section-${mode}`}
      id="contact"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 mb-5 p-4">
            <div className="card scroll-background" data-aos="fade-up-right">
              <div className="card-body letter">
                <h6 className="card-subtitle mb-2">{newDate}</h6>
                <p className="card-text text-center">
                  Regarding {mail.subject}
                </p>
                <p className="card-text">Dear {detail.userDetail.fullName},</p>
                <p className="card-text mx-4">{mail.message}</p>
                <p className="card-text mb-1">Sincerely,</p>
                <p className="card-text">{mail.fullName}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 form mt-5">
            <div className="row">
              <div className="col mb-3">
                <SectionTitle title="Work with us" />
              </div>
            </div>
            <ContactForm onChange={onChange} onClose={handleClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
