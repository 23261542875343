import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import SectionTitle from "./SectionTitle";
import ItemList from "./ItemList";
import axiosInstance from "../interceptor/AxiosInstance";

const MenuList = () => {
  const mode = useSelector((state) => state.mode);
  const [requestBody, setRequestBody] = useState({
    pageNumber: 1,
    noOfRecords: 100,
    search: [
      {
        field: "tags",
        value: null,
      },
    ],
    actionType: "FILTER",
  });
  const [items, setItems] = useState([]);

  const fetchItemList = () => {
    axiosInstance
      .post("item/find", requestBody)
      .then((res) => {
        setItems(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchItemList();
  }, []);

  const onChange = (e) => {
    const newValue = e.target.value;
    setRequestBody((prevState) => ({
      ...prevState,
      search: [{ ...prevState.search[0], value: newValue }],
    }));
  };

  const fetchItems = (e) => {
    e.preventDefault();
    fetchItemList();
  };

  return (
    <div className={`container menu-list menu-list-${mode} mt-5`}>
      <form onSubmit={fetchItems} className="pt-5">
        <div className="row justify-content-end">
          <div className="col-md-4">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingText1"
                placeholder=""
                name="name"
                value={requestBody.search[0].value}
                onChange={onChange}
                required
              />
              <label htmlFor="floatingText1">Search</label>
            </div>
          </div>
          <div className="col-md-auto d-flex align-items-center">
            <button
              type="submit"
              className={`btn btn-md btn-rounded btn-outline-${mode} px-3`}
            >
              <FaSearch />
            </button>
          </div>
        </div>
      </form>
      <div className="row my-5">
        <div className="col">
          <SectionTitle title="Menu" />
        </div>
      </div>
      {items.map((item) => {
        return <ItemList key={item.id} item={item} />;
      })}
    </div>
  );
};

export default MenuList;
