const reducer = (state = "light", action) => {
  if (action.type === "lightMode") {
    return action.payload;
  } else if (action.type === "darkMode") {
    return action.payload;
  } else {
    return state;
  }
};

export default reducer;
