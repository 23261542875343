import React, { useContext } from "react";
import detailContext from "../context/detailContext";
import { useNavigate } from "react-router-dom";

const ItemList = (props) => {
  const detail = useContext(detailContext);
  const navigate = useNavigate();
  const imageUrl =
    props.item.itemImageUrl === null
      ? require("../assets/images/items/americano.jpg")
      : detail.fileStashUrl + props.item.itemImageUrl;

  const viewItemDetail = () => {
    navigate("/item-detail/" + props.item.id);
  };
  return (
    <div className="container mb-4" onClick={viewItemDetail}>
      <div className="row">
        <div className="col-2 col-lg-1">
          <img src={imageUrl} className="card-img-top" alt="..." />
        </div>
        <div className="col-7">{props.item.name}</div>
        <div className="col-2 d-none d-lg-block">{props.item.type}</div>
        <div className="col-3 col-lg-1">Rs. {props.item.sellingPrice}</div>
      </div>
    </div>
  );
};

export default ItemList;
