import React, { Component } from "react";
import DetailState from "../context/DetailState";
import Banner from "./Banner";
import Jumbotron from "./Jumbotron";
import Menu from "./Menu";
import About from "./About";
import Contact from "./Contact";

export default class Home extends Component {
  render() {
    return (
      <DetailState>
        <Banner />
        <Menu />
        <About />
        <Jumbotron />
        <Contact />
      </DetailState>
    );
  }
}
