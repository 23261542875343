import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { IoLogoBitcoin } from "react-icons/io";
import SectionTitle from "./SectionTitle";
import { useNavigate, useParams } from "react-router-dom";
import CoinFlowChart from "./CoinFlowChart";
import axiosInstance from "../interceptor/AxiosInstance";
import detailContext from "../context/detailContext";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const RedeemPanel = () => {
  const mode = useSelector((state) => state.mode);
  const navigate = useNavigate();
  const params = useParams();
  const detail = useContext(detailContext);
  const [redeemRequest, setRedeemRequest] = useState({
    fullName: "",
    mobileNumber: "",
    redeemCode: params.code,
  });

  const [captchaVal, setCaptchaVal] = useState(null);

  const redeem = (e) => {
    e.preventDefault();
    axiosInstance
      .post("/redeem-code/redeem-without-auth", redeemRequest)
      .then((res) => {
        toast.success(res.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: mode,
        });
        axiosInstance
          .post("/customer/view-without-auth", redeemRequest)
          .then((res) => {
            detail.setCustomer(res.data);
            console.log(detail.customer);
            navigate("/customer-detail");
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const onChange = (e) => {
    setRedeemRequest({ ...redeemRequest, [e.target.name]: e.target.value });
  };

  return (
    <div
      className={`container-fluid contact contact-${mode} padding mt-5`}
      id="redeem-panel"
    >
      <div className="row p-5">
        <div className="col-lg-5 col-md-12">
          <img
            src={require("../assets/images/cappuccino.png")}
            className="img-fluid"
            alt="cappuccino"
          />
        </div>
        <div className="col-lg-6 col-md-12 p-3 mt-5">
          <div className="row mt-5 mb-2">
            <div className="col">
              <SectionTitle title="Redeem" />
            </div>
          </div>
          <form onSubmit={redeem}>
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingText1"
                    placeholder="Mobile Number"
                    name="mobileNumber"
                    onChange={onChange}
                    required
                  />
                  <label htmlFor="floatingText1">Mobile Number</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingText1"
                    placeholder="Full Name"
                    name="fullName"
                    onChange={onChange}
                    required
                  />
                  <label htmlFor="floatingText1">Full Name</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingText1"
                    placeholder="Redeem Code"
                    name="redeemCode"
                    // value={params.code}
                    value={redeemRequest.redeemCode}
                    onChange={onChange}
                    required
                  />
                  <label htmlFor="floatingText1">Redeem Code</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col d-flex justify-content-center align-items-center">
                <ReCAPTCHA
                  sitekey="6Lf9cV4qAAAAAIuC9CxThcE4CRaW8FW5Uk35ye7C"
                  onChange={(val) => setCaptchaVal(val)}
                />
              </div>
            </div>

            <div className="row p-3">
              <button
                type="submit"
                value="Send"
                disabled={!captchaVal}
                className={`btn btn-md btn-rounded btn-outline-${mode} btn-block px-3`}
              >
                Redeem <IoLogoBitcoin />
              </button>
            </div>
          </form>
        </div>
      </div>
      <CoinFlowChart />
    </div>
  );
};

export default RedeemPanel;
