import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import SectionTitle from "./SectionTitle";
import { useNavigate } from "react-router-dom";
import CoinFlowChart from "./CoinFlowChart";
import detailContext from "../context/detailContext";
import axiosInstance from "../interceptor/AxiosInstance";

const ProfileForm = () => {
  const mode = useSelector((state) => state.mode);
  const detail = useContext(detailContext);
  const [customerDetail, setCustomerDetail] = useState({
    fullName: "",
    mobileNumber: "",
  });

  const navigate = useNavigate();
  const viewCustomerDetail = (e) => {
    e.preventDefault();
    axiosInstance
      .post("/customer/view-without-auth", customerDetail)
      .then((res) => {
        detail.setCustomer(res.data);
        navigate("/customer-detail");
      })
      .catch((err) => console.log(err));
  };

  const onChange = (e) => {
    setCustomerDetail({ ...customerDetail, [e.target.name]: e.target.value });
  };

  return (
    <div
      className={`container-fluid contact contact-${mode} padding my-4`}
      id="profile-form"
    >
      <div className="row p-5">
        <div className="col-lg-5 col-md-12 mt-5">
          <img
            src={require("../assets/images/poly-coffee.png")}
            className="img-fluid"
            alt="cappuccino"
          />
        </div>
        <div className="col-lg-6 col-md-12 p-3 mt-5">
          <div className="row mt-5 mb-2">
            <div className="col">
              <SectionTitle title="Profile" />
            </div>
          </div>
          <form onSubmit={viewCustomerDetail}>
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingText1"
                    placeholder="Mobile Number"
                    name="mobileNumber"
                    onChange={onChange}
                    required
                  />
                  <label htmlFor="floatingText1">Mobile Number</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingText1"
                    placeholder="Full Name"
                    name="fullName"
                    onChange={onChange}
                    required
                  />
                  <label htmlFor="floatingText1">Full Name</label>
                </div>
              </div>
            </div>
            <div className="row d-flex flex-row-reverse">
              <div className="col-auto">
                <button
                  type="submit"
                  value="Send"
                  className={`btn btn-md btn-rounded btn-outline-${mode} btn-block px-3`}
                >
                  <FaEye />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <CoinFlowChart />
    </div>
  );
};

export default ProfileForm;
