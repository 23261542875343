import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ModeButton from "./ModeButton";
import { Link } from "react-router-dom";

const Navbar = () => {
  const mode = useSelector((state) => state.mode);
  useEffect(() => {
    let lastScroll = 0;
    window.addEventListener("scroll", function () {
      const navigationBar = this.document.getElementsByClassName("navbar")[0];
      let scroll = window.scrollY;
      if (scroll >= lastScroll) {
        navigationBar.classList.add("scrolled-down");
      } else {
        navigationBar.classList.remove("scrolled-down");
      }
      lastScroll = scroll;
    });
  }, []);
  return (
    <nav className={`navbar fixed-top navbar-expand-lg navbar-${mode} p-md-3`}>
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item mx-2">
              <Link
                className={`nav-link ${
                  window.location.pathname === "/home" && "active"
                }`}
                to="/home"
              >
                Home
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link
                className={`nav-link ${
                  window.location.pathname === "/view-profile" && "active"
                }`}
                to={`/view-profile`}
              >
                Customer
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link
                className={`nav-link ${
                  window.location.pathname === "/menu" && "active"
                }`}
                to={`/menu`}
              >
                Menu
              </Link>
            </li>
          </ul>
          <ModeButton />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
