import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SectionTitle from "./SectionTitle";
import Item from "./Item";
import axiosInstance from "../interceptor/AxiosInstance";

const Menu = () => {
  const [itemList, setItemList] = useState([]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const fetchItemList = () => {
    axiosInstance
      .get("item/find?pageNumber=1&pageSize=100")
      .then((res) => {
        setItemList(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchItemList();
  }, []);

  return (
    <div className="container mb-5" id="menu">
      <div className="row">
        <div className="col">
          <SectionTitle title="Menu" />
        </div>
      </div>
      <div className="row d-flex justify-content-center align-items-center menu-container">
        <div className="col-12">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            arrows={false}
            className="p-4 mb-5"
          >
            {itemList.map((item) => {
              return <Item key={item.id} item={item} />;
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Menu;
