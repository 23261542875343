import React, { useContext } from "react";
import { useSelector } from "react-redux";
import detailContext from "../context/detailContext";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const Item = (props) => {
  const detail = useContext(detailContext);
  const mode = useSelector((state) => state.mode);
  const navigate = useNavigate();

  const imageUrl =
    props.item.itemImageUrl === null
      ? require("../assets/images/items/americano.jpg")
      : detail.fileStashUrl + props.item.itemImageUrl;

  const viewItemDetail = () => {
    navigate("/item-detail/" + props.item.id);
  };
  return (
    <div>
      <div
        className={`item-card item-card-${mode} card`}
        style={{ width: "18rem" }}
      >
        <div className="card-img-container">
          <img src={imageUrl} className="card-img-top" alt="..." />
          <p className="card-price">
            {props.item.markedPrice > props.item.sellingPrice ? (
              <small>Rs. {props.item.markedPrice}</small>
            ) : (
              ""
            )}{" "}
            Rs. {props.item.sellingPrice}
          </p>
        </div>
        <div className="card-body">
          <h5 className="card-title">{props.item.name}</h5>
          <p
            className="card-text"
            dangerouslySetInnerHTML={{ __html: props.item.description }}
          />
          <div className="d-flex justify-content-end">
            <button
              className={`btn btn-md btn-rounded btn-outline-${mode} btn-block px-3`}
              onClick={viewItemDetail}
            >
              <FaEye />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
