import React from "react";
import { useSelector } from "react-redux";

const SectionTitle = (props) => {
  let mode = useSelector((state) => state.mode);
  return (
    <div className={`section-break-title section-break-title-${mode}`}>
      <h2 className="lead">{props.title}</h2>
    </div>
  );
};

export default SectionTitle;
