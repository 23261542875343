import axios from "axios";
import { toast } from "react-toastify";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://cafebizarre.com.np/prod/v1/", // Replace with your API base URL
  timeout: 10000, // Optional: Set a timeout (10 seconds here)
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
  },
});

// Request interceptor (optional, if you need to modify requests)
axiosInstance.interceptors.request.use(
  (config) => {
    // You can modify the request config here if needed
    // For example, add an authorization token
    // config.headers['Authorization'] = 'Bearer your-token';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data; // Return the response data
    }
    return response; // Return other responses as-is
  },
  (error) => {
    var message = "";
    if (error.response) {
      if (error.response.status === 400) {
        message = error.response.data.errorMessage;
      } else {
        message = error.code;
      }

      console.log(error);
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
