import React from "react";
import { useSelector } from "react-redux";

const CoinFlowChart = () => {
  const mode = useSelector((state) => state.mode);
  return (
    <div className={`container flow-chart-${mode} mt-5`}>
      <div className="row">
        <div className="col mt-3">
          <h1 className="text-center my-5">How Does Bizarre Coin Work </h1>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center align-items-center">
          <img
            src={
              mode === "light"
                ? require("../assets/images/bizarre-flowchart-light.png")
                : require("../assets/images/bizarre-flowchart-dark.png")
            }
            className="img-fluid"
            alt="bizarre-flowchart"
          />
        </div>
      </div>
    </div>
  );
};

export default CoinFlowChart;
