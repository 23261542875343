import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../interceptor/AxiosInstance";
import detailContext from "../context/detailContext";
import SectionTitle from "./SectionTitle";

const ItemDetail = () => {
  const params = useParams();
  const detail = useContext(detailContext);
  const [item, setItem] = useState({
    name: "Item Name",
    description: "None",
    markedPrice: 0,
    sellingPrice: 0,
    type: "BEVERAGE",
  });

  const imageUrl =
    item.itemImageUrl === null
      ? require("../assets/images/items/americano.jpg")
      : detail.fileStashUrl + item.itemImageUrl;

  const fetchItem = () => {
    axiosInstance
      .get("item/" + params.id)
      .then((res) => {
        setItem(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchItem();
  }, []);

  return (
    <div className="container mt-5 p-5">
      <div className="row">
        <div className="col">
          <SectionTitle title={item.type} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12 my-5">
          <img className="img-fluid item-img" src={imageUrl} alt=""></img>
        </div>
        <div className="col-lg-6 col-md-12 p-lg-5">
          <h1>{item.name}</h1>
          <p>
            {item.markedPrice > item.sellingPrice ? (
              <small className="marked-price">Rs. {item.markedPrice}</small>
            ) : (
              ""
            )}{" "}
            Rs. {item.sellingPrice}
          </p>
          <div
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: item.description }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetail;
