import React, { useContext } from "react";
import { useSelector } from "react-redux";
import detailContext from "../context/detailContext";
import { useNavigate } from "react-router-dom";

const Jumbotron = () => {
  const mode = useSelector((state) => state.mode);
  const detail = useContext(detailContext);

  const navigate = useNavigate();
  const navigateToJoin = () => {
    navigate("/join");
  };

  return (
    <div id="cta" className={`cta mb-5 cta-${mode}`}>
      <div className="container p-2" data-aos="zoom-in">
        <div className="text-center">
          <h3 style={{}}>{detail.userDetail.jumbotronTitle}</h3>
          <p>{detail.userDetail.jumbotronText}</p>
          <button
            onClick={navigateToJoin}
            className={`btn btn-md btn-rounded btn-${mode} mb-3 px-3`}
            data-aos="flip-left"
          >
            Join Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Jumbotron;
