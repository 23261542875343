import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaPaperPlane } from "react-icons/fa";
import axiosInstance from "../interceptor/AxiosInstance";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = (props) => {
  const mode = useSelector((state) => state.mode);
  const [mail, setMail] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    subject: props.subject,
    message: "I'd love to chat with you about...",
  });

  const [captchaVal, setCaptchaVal] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();
    toast.info("Sending mail...", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: mode,
    });
    axiosInstance
      .post("/email/send-collaboration-mail", mail)
      .then((res) =>
        toast.success(res.data, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: mode,
        })
      )
      .catch((err) => console.log(err));
    setMail({
      fullName: "",
      email: "",
      mobileNumber: "",
      subject: "",
      message: "I'd love to chat with you about...",
    });
  };

  const onChange = (e) => {
    setMail({ ...mail, [e.target.name]: e.target.value });
    props.onChange({ ...mail, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className="mt-3">
        <form onSubmit={sendEmail}>
          <div className="row">
            <div className="col-8">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingText1"
                  placeholder=""
                  name="fullName"
                  value={mail.fullName}
                  onChange={onChange}
                  required
                />
                <label htmlFor="floatingText1">Full Name</label>
              </div>
            </div>
            <div className="col-4">
              <select
                className="form-select form-select-md"
                aria-label="Default select example"
                name="subject"
                onChange={onChange}
                disabled={props.disabled}
              >
                <option defaultValue={props.subject}>{props.subject}</option>
                <option value="Job Application">Job Application</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingEmail"
                  placeholder=""
                  name="email"
                  value={mail.email}
                  onChange={onChange}
                  required
                />
                <label htmlFor="floatingEmail">Email</label>
              </div>
            </div>
            <div className="col-6">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingEmail"
                  placeholder=""
                  name="mobileNumber"
                  value={mail.mobileNumber}
                  onChange={onChange}
                  required
                />
                <label htmlFor="floatingEmail">Mobile Number</label>
              </div>
            </div>
          </div>

          <div className="form-floating">
            <textarea
              className="form-control mb-3"
              placeholder=""
              id="floatingTextarea2"
              name="message"
              value={mail.message}
              onChange={onChange}
              required
            ></textarea>
            <label htmlFor="floatingTextarea2">Message</label>
          </div>

          <div className="row">
            <div className="col d-flex justify-content-center align-items-center">
              <ReCAPTCHA
                sitekey="6Lf9cV4qAAAAAIuC9CxThcE4CRaW8FW5Uk35ye7C"
                onChange={(val) => setCaptchaVal(val)}
              />
            </div>
          </div>

          <div className="row p-3">
            <button
              type="submit"
              value="Send"
              disabled={!captchaVal}
              className={`btn btn-md btn-rounded btn-outline-${mode} mb-3 px-3`}
              data-aos="flip-left"
            >
              <FaPaperPlane />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

ContactForm.defaultProps = {
  subject: "Franchise",
  disabled: false,
};

export default ContactForm;
