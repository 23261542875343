import React, { useContext } from "react";
import SectionTitle from "./SectionTitle";
import detailContext from "../context/detailContext";
import { useSelector } from "react-redux";

const About = () => {
  const detail = useContext(detailContext);
  const splitName = detail.userDetail.fullName.split(" ");
  const mode = useSelector((state) => state.mode);

  return (
    <div className="container padding" id="about">
      <div className="row">
        <div className="col mb-3">
          <SectionTitle title="About us" />
        </div>
      </div>
      <div className={`container-fluid about about-${mode}`}>
        <div className="row">
          <div className="col">
            <span className={`about-icon about-icon-${mode}`}>
              {detail.aboutIcon}
            </span>
          </div>
        </div>
        <div className="row p-5">
          <div className="col-lg-7 col-md-12">
            <h1>{splitName[0]}</h1>
            <h1 data-aos="fade-down">{splitName[1]}</h1>
            <div
              className={`about-para about-para-${mode} px-lg-5 mt-5`}
              dangerouslySetInnerHTML={{ __html: detail.userDetail.about }}
            />
          </div>
          <div className="col-lg-5 col-md-12">
            <img
              className="img-fluid"
              src={require("../assets/images/bizarre-bros-outline.png")}
              alt="About-User"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            {detail.links.map((link) => {
              return (
                <small key={link.id}>
                  <a
                    className={`a-${mode} mx-2`}
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.platform}
                  </a>
                </small>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
