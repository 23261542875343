import React from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../state/index";

const ModeButton = () => {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.mode);
  const handleClick = (mode) => {
    if (mode === "dark") {
      changeStyle(mode);
      dispatch(actionCreators.darkMode());
    } else if (mode === "light") {
      changeStyle(mode);
      dispatch(actionCreators.lightMode());
    }
  };
  const changeStyle = (mode) => {
    if (mode === "dark") {
      document.body.style.backgroundImage = "linear-gradient(#5075b9,#2d1458)";
      document.body.style.color = "#FCEAFF";
    } else if (mode === "light") {
      document.body.style.backgroundImage = "linear-gradient(#E6F1E1,#A4C5F8)";
      document.body.style.color = "#683E00";
    } else {
      return;
    }
  };
  return (
    <div>
      {mode === "light" ? (
        <button
          className={`btn btn-mode btn-mode-${mode} ${
            mode === "light" ? "visible" : "invisible"
          }`}
          onClick={() => handleClick("dark")}
        >
          <FaMoon />
        </button>
      ) : (
        <button
          className={`btn btn-mode btn-mode-${mode} ${
            mode === "dark" ? "visible" : "invisible"
          }`}
          onClick={() => handleClick("light")}
        >
          <FaSun />
        </button>
      )}
    </div>
  );
};

export default ModeButton;
