export const darkMode = () => {
  return (dispatch) => {
    dispatch({
      type: "darkMode",
      payload: "dark",
    });
  };
};

export const lightMode = () => {
  return (dispatch) => {
    dispatch({
      type: "lightMode",
      payload: "light",
    });
  };
};
