import React, { useContext } from "react";
import CoinFlowChart from "./CoinFlowChart";
import { MdVerified } from "react-icons/md";
import { IoLogoBitcoin } from "react-icons/io";
import { useSelector } from "react-redux";
import detailContext from "../context/detailContext";

const CustomerDetail = () => {
  const mode = useSelector((state) => state.mode);
  const detail = useContext(detailContext);
  let customer = detail.customer;
  return (
    <div className="container mt-4 p-5">
      <div className="row">
        <div className="col">
          <h1 className={`customer ${"Bronze".toLowerCase()}-customer`}>
            {customer.fullName.toUpperCase()}{" "}
            {customer.verified ? (
              <span className={`verified-icon verified-icon-${mode}`}>
                <MdVerified />
              </span>
            ) : (
              ""
            )}
          </h1>
          <h1>{customer.league} League</h1>
          <small>
            <em>
              Use redeem coins or purchase more items to earn more Bizarre
              Coins!
            </em>
          </small>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center align-items-center">
          <i className="fab fa-bitcoin" data-aos="flip-left"></i>
        </div>
      </div>
      <div className="row m-5">
        <div className="col d-flex justify-content-center align-items-center">
          <h2 className="mb-5">
            <IoLogoBitcoin className="coin-currency" />
            {customer.coins}
          </h2>
        </div>
      </div>
      <CoinFlowChart />
    </div>
  );
};

export default CustomerDetail;
