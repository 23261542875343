import React from "react";
import { useSelector } from "react-redux";

const NotFound = () => {
  const mode = useSelector((state) => state.mode);
  return (
    <div className="container-fluid mt-5 p-5 d-flex justify-content-center align-items-center">
      <h1 className={`not-found not-found-${mode}`}>Not Found!</h1>
    </div>
  );
};

export default NotFound;
